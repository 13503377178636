import Swiper from 'swiper';
import {
   Navigation,
   Pagination,
   Autoplay
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import lazySizes from 'lazysizes';
import 'fslightbox';
import 'vanilla-cookieconsent'

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', init)

function init() {
   pageLoad();
   handleLazyLoad();
   nav();
   footer();
   swipers();
   caseCardsVertical();
   caseCardsSwiper();
   caseNumber();
   tabs();
   newContentLoaded();
   cookieConsent();
   viewport();
   refreshLightbox();
   indieEvents();
   animations();
   lazyVideos();
}

function pageLoad() {
   const transitionClass = 'js-transition';
   window.addEventListener('load', () => document.body.classList.remove(transitionClass));
}

function nav() {
   const mm = gsap.matchMedia();
   const hamburger = document.querySelector('.c-nav__hamburger');
   const hamburgerActiveClass = 'c-nav--hamburger';
   const nav = document.querySelector('.c-nav');
   const navActiveClass = 'c-nav--active';
   const mobileNav = document.querySelector('.c-nav__mobile');
   const mobileNavLinks = document.querySelectorAll('.c-nav__mobile-link');
   const changeNavColor = gsap.utils.toArray('.js-change-nav');
   const navBgClass = 'c-nav--bg';
   let hamburgerToggle = true;
   let navActivated = false;

   gsap.set(mobileNavLinks, {
      yPercent: 100,
      opacity: 0
   });

   hamburger.onclick = () => {
      if (hamburgerToggle) {
         hamburgerToggle = false;

         nav.classList.add(hamburgerActiveClass);

         openNav();

         if (!toggleNav()) {
            toggleNav(true);
            navActivated = true;
         }

      } else {
         hamburgerToggle = true;

         closeNav();

         setTimeout(() => {
            nav.classList.remove(hamburgerActiveClass);
         }, 500);

         if (navActivated) {
            setTimeout(() => {
               toggleNav(false);
               navActivated = false;
            }, 800);
         }
      }
   };

   if (changeNavColor.length) {
      changeNavColor.forEach(e => {
         setTimeout(() => {
            const navHeight = (nav.offsetHeight / 1.3) + 'px';
            ScrollTrigger.create({
               trigger: e,
               start: `top ${navHeight}`,
               end: `bottom ${navHeight}`,
               toggleClass: {
                  targets: nav,
                  className: navActiveClass
               }
            })
         }, 100);
      });
   };

   mm.add('(min-width: 768px)', () => {
      window.addEventListener('scroll', () => {
         if (window.scrollY > 20) nav.classList.add(navBgClass);
         else nav.classList.remove(navBgClass);
      });
   });


   function toggleNav(active) {
      if (active) {
         nav.classList.add(navActiveClass);
      } else if (active === false) {
         nav.classList.remove(navActiveClass);
      } else {
         if (nav.classList.contains(navActiveClass)) return true;
         return false;
      }
   }

   function openNav() {
      const tlMenu = gsap.timeline();
      tlMenu.set(mobileNavLinks, {
         yPercent: 100,
         opacity: 0
      });
      tlMenu.to(mobileNav, {
         display: 'flex'
      })
      mobileNavLinks.forEach(function (e, i) {
         tlMenu.to(e, {
            duration: 0.4,
            force3D: true,
            yPercent: 0,
            opacity: 1,
            delay: 0.3,
            ease: 'power2.easeOut'
         }, i * 0.1)
      });
      nav.addEventListener('pointermove', preventDefault);
   }

   function closeNav() {
      const tlMenu = gsap.timeline();
      mobileNavLinks.forEach(function (e, i) {
         tlMenu.to(e, 0.5, {
            force3D: true,
            yPercent: -100,
            opacity: 0,
            delay: 0,
            ease: 'power2.easeIn'
         }, i * 0.05)
      });
      tlMenu.to(mobileNav, {
         display: 'none'
      });
      nav.removeEventListener('pointermove', preventDefault);
   }

   function preventDefault(e) {
      e.preventDefault();
   }
}

function footer() {
   const footer = document.querySelector('.c-footer');
   if (!footer) return;

   const setupAnimation = () => {
      gsap.set('.c-footer__container', {
         yPercent: -30
      });
      const uncover = gsap.timeline({
            paused: true
         })
         .to('.c-footer__container', {
            yPercent: 0,
            ease: 'none'
         });

      const existingTrigger = ScrollTrigger.getById('js-footer');
      if (existingTrigger) existingTrigger.kill();

      ScrollTrigger.create({
         id: 'js-footer',
         trigger: '.c-footer',
         start: 'top bottom',
         end: () => `${window.innerHeight} bottom`,
         animation: uncover,
         scrub: true
      });
   };

   setupAnimation();

   const debounce = (func, wait) => {
      let timeout;
      return () => {
         clearTimeout(timeout);
         timeout = setTimeout(func, wait);
      };
   };

   const handleResize = debounce(setupAnimation, 250);
   window.addEventListener('resize', handleResize);
}

function swipers() {
   const logoLength = () => {
      const logoSlides = document.querySelectorAll('.js-logo-swiper .swiper-slide').length;
      return logoSlides > 4;
   }
   new Swiper('.js-slider-swiper', {
      slidesPerView: 1,
      centeredSlides: true,
      speed: 700,
      spaceBetween: 15,
      breakpoints: {
         768: {
            spaceBetween: 25,
         },
         1200: {
            spaceBetween: 35,
         },
         1400: {
            spaceBetween: 70,
         },
      }
   });
   new Swiper('.js-news-swiper', {
      slidesPerView: 1,
      centeredSlides: true,
      speed: 700,
      loop: true,
      modules: [Navigation, Pagination],
      navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev',
      },
      pagination: {
         el: '.swiper-pagination',
         type: 'bullets',
         dynamicBullets: true,
      },
   });
   new Swiper('.js-featured-news-swiper', {
      slidesPerView: 1.2,
      speed: 700,
      spaceBetween: 15,
      modules: [Navigation],
      navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev',
      },
      breakpoints: {
         768: {
            slidesPerView: 2,
            spaceBetween: 25,
         },
         1200: {
            slidesPerView: 2,
            spaceBetween: 35,
         },
         1400: {
            slidesPerView: 2,
            spaceBetween: 70,
         },
      }
   });
   new Swiper('.js-logo-swiper', {
      slidesPerView: 2,
      speed: 700,
      loop: logoLength(),
      autoplay: logoLength(),
      noSwiping: true,
      modules: [Autoplay],
      breakpoints: {
         576: {
            slidesPerView: 3,
         },
         768: {
            slidesPerView: 4,
         }
      }
   });
}

function caseCardsSwiper() {
   const breakpoint = window.matchMedia('(min-width: 992px)');
   const swiper = document.querySelector('.c-cards__swiper');
   let mySwiper;

   if (!swiper) return;

   const breakpointChecker = () => {
      if (breakpoint.matches) {
         if (mySwiper !== undefined) mySwiper.destroy(true, true);
         return;

      } else if (breakpoint.matches === false) {
         return enableSwiper();
      }
   };

   const enableSwiper = () => {
      mySwiper = new Swiper('.c-cards__swiper', {
         slidesPerView: 1,
         spaceBetween: 20,
         breakpoints: {
            576: {
               slidesPerView: 1.6
            },
         }
      });
   };

   breakpoint.addListener(breakpointChecker);
   breakpointChecker();
}

function caseNumber() {
   const numbers = document.querySelectorAll('.js-case-number');
   const numbersLength = numbers.length;

   if (!numbers) return;

   numbers.forEach((e, i) => {
      let firstNumber = e.querySelector('.js-case-number--first');
      let lastNumber = e.querySelector('.js-case-number--last');
      firstNumber.innerHTML = i + 1;
      lastNumber.innerHTML = numbersLength;
   });
}

function tabs() {
   const tabs = document.querySelector(".c-tabs");
   const tabsBtns = document.querySelectorAll(".c-tabs__item");

   if (!tabs) return;

   function displayCurrentTab(current) {
      for (let i = 0; i < tabsBtns.length; i++) {
         const btnActiveClass = "c-tabs__item--active";
         if (current === i) tabsBtns[i].classList.add(btnActiveClass);
         else tabsBtns[i].classList.remove(btnActiveClass);
      }
   }

   tabs.addEventListener("click", event => {
      for (let i = 0; i < tabsBtns.length; i++) {
         if (event.target === tabsBtns[i]) displayCurrentTab(i);
      }
   });
}

function newContentLoaded() {
   const content = document.querySelector('.js-content-loader');
   const tabs = document.querySelectorAll('.c-tabs__item');
   const search = document.querySelector('.js-search');
   const tabActiveClass = 'c-tabs__item--active';
   const animDuration = .1;

   if (!content) return;

   pagination();

   search.addEventListener('keyup', (e) => {
      if (e.code.includes('Key', 0)) fadeOut(content);
   });

   tabs.forEach((e) => e.addEventListener('click', (e) => {
      if (!e.target.classList.contains(tabActiveClass)) fadeOut(content)
   }));

   document.addEventListener('htmx:afterSwap', () => {
      caseCardsVertical();
      pagination();
      fadeIn(content);
      ScrollTrigger.refresh();
   });

   function pagination() {
      const paginationLinks = document.querySelectorAll('.js-pagination');
      paginationLinks.forEach((e, i) => e.addEventListener('click', () => {
         scrollTo(0, newPage, i);
      }));
   }

   function scrollTo(offset, callback, callbackValue) {
      const fixedOffset = offset.toFixed();
      const onScroll = () => {
         if (window.pageYOffset.toFixed() === fixedOffset) {
            window.removeEventListener('scroll', onScroll)
            callback(callbackValue)
         }
      }
      window.addEventListener('scroll', onScroll)
      onScroll()
      window.scrollTo({
         top: offset,
         behavior: 'smooth'
      })
   }

   function newPage(index) {
      fadeOut(content)
      document.querySelectorAll('.c-pagination__url')[index].click();
   }

   function fadeIn(e) {
      gsap.to(e, {
         opacity: 1,
         duration: animDuration
      });
   }

   function fadeOut(e) {
      gsap.to(e, {
         opacity: 0,
         duration: animDuration
      });
   }
}

function viewport() {
   let initialWidth = window.innerWidth;
   const windowHeight = () => {
      let currentWidth = window.innerWidth;
      if (currentWidth !== initialWidth) addHeight();
      initialWidth = currentWidth;
   }
   const addHeight = () => document.body.style.setProperty('--height', `${window.innerHeight/10}rem`);
   window.addEventListener('resize', windowHeight);
   addHeight();
}

function cookieConsent() {
   var cc = initCookieConsent();

   setTimeout(() => {
      cc.run({
         current_lang: 'en',

         gui_options: {
            consent_modal: {
               layout: 'box',
               position: 'bottom left',
               transition: 'slide',
               swap_buttons: false
            },
            settings_modal: {
               layout: 'box',
               transition: 'slide'
            }
         },

         languages: {
            'nl': {
               consent_modal: {
                  title: 'We gebruiken cookies! 🍪',
                  description: 'Net als jij houden wij ook van cookies, maar dan van een andere soort. Deze kleine digitale traktaties helpen ons om je ervaring op onze website zo smakelijk mogelijk te maken. We beloven dat elke kruimel zal bijdragen aan de verbetering van je ervaring op onze website.Voel je vrij om de cookie-instellingen naar je smaak aan te passen. <button type="button" data-cc="c-settings" class="cc-link">Laat me kiezen</button>',
                  primary_btn: {
                     text: 'Accepteer alles',
                     role: 'accept_all' // 'accept_selected' or 'accept_all'
                  },
                  secondary_btn: {
                     text: 'Weiger alles',
                     role: 'accept_necessary' // 'settings' or 'accept_necessary'
                  }
               },
               settings_modal: {
                  title: 'Cookievoorkeuren',
                  save_settings_btn: 'Instellingen opslaan',
                  accept_all_btn: 'Accepteer alles',
                  reject_all_btn: 'Weiger alles',
                  close_btn_label: 'Sluiten',
                  cookie_table_headers: [{
                        col1: 'Naam'
                     },
                     {
                        col2: 'Domain'
                     },
                     {
                        col3: 'Vervaldatum'
                     },
                     {
                        col4: 'Beschrijving'
                     }
                  ],
                  blocks: [{
                     title: 'Cookiegebruik 📢',
                     description: 'Wij gebruiken cookies om de basisfuncties van de website te garanderen en je online ervaring te verbeteren. Je kan voor elke categorie kiezen voor opt-in/out wanneer je maar wilt. Lees het volledige privacybeleid voor meer informatie over cookies en andere gevoelige gegevens. <a href="/algemene-voorwaarden" class="cc-link">Algemene voorwaarden</a>.'
                  }, {
                     title: 'Strikt noodzakelijke cookies',
                     description: 'Deze cookies zijn essentieel voor het goed functioneren van onze website. Zonder deze cookies kan de website niet optimaal functioneren.',
                     toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                     }
                  }, {
                     title: 'Prestatie- en analytische cookies',
                     description: 'Met deze cookies kan de website de keuzes onthouden die je in het verleden hebt gemaakt. Ze helpen ons om je ervaring te verbeteren door te begrijpen hoe je onze site gebruikt.',
                     toggle: {
                        value: 'analytics', // your cookie category
                        enabled: false,
                        readonly: false
                     },
                     cookie_table: [ // list of all expected cookies
                        {
                           col1: '^_ga', // match all cookies starting with "_ga"
                           col2: 'google.com',
                           col3: '2 years',
                           col4: 'description ...',
                           is_regex: true
                        },
                        {
                           col1: '_gid',
                           col2: 'google.com',
                           col3: '1 day',
                           col4: 'description ...',
                        }
                     ]
                  }, {
                     title: 'Advertentie en targeting cookies',
                     description: "Deze cookies verzamelen informatie over hoe je de website gebruikt, welke pagina's je hebt bezocht en op welke links je hebt geklikt.Alle gegevens worden geanonimiseerd en kunnen niet worden gebruikt om je te identificeren.",
                     toggle: {
                        value: 'targeting',
                        enabled: false,
                        readonly: false
                     }
                  }, {
                     title: 'Meer informatie',
                     description: 'Neem contact met ons op als je vragen hebt over ons cookiebeleid en je keuzes. <a class="cc-link" href="/contact">Neem contact met ons op</a>.',
                  }]
               }
            }
         }
      });
   }, 3000)
}

function refreshLightbox() {
   const lightboxes = document.querySelectorAll('[data-fslightbox]');
   if (lightboxes.length) refreshFsLightbox()
   else return;
}

function indieEvents() {
   const eventBtn = document.querySelector('.js-event-btn');
   const eventForm = document.querySelector('.fui-form');
   const navHeight = document.querySelector('.c-nav').clientHeight * 1.5;
   if (eventBtn && eventForm) {
      eventBtn.addEventListener('click', (e) => {
         e.preventDefault();
         window.scrollTo({
            top: eventForm.getBoundingClientRect().top + window.scrollY - navHeight,
            behavior: 'smooth'
         });
      });
   } else if (eventBtn) eventBtn.remove();
   else return;
}

function animations() {
   // ANIMATED IMAGE
   const animatedImages = document.querySelectorAll('.c-animated-image');
   if (animatedImages.length) {
      animatedImages.forEach(e => {
         gsap.to(e, {
            clipPath: 'inset(0% 0%)',
            scrollTrigger: {
               trigger: e,
               scrub: 0,
               start: 'top bottom',
               end: 'bottom bottom',
            }
         });
      });
   }
}

function handleLazyLoad() {
   let lazyImages = gsap.utils.toArray("img[loading='lazy']"),
      imgLoaded = lazyImages.length,
      onImgLoad = () => --imgLoaded || ScrollTrigger.refresh();
   lazyImages.forEach((img) => img.naturalWidth ? onImgLoad() : img.addEventListener('load', onImgLoad));
}

window.caseCardsVertical = function caseCardsVertical() {
   const caseCard = document.querySelector('.c-cards__wrapper');
   if (!caseCard) return;
   ScrollTrigger.saveStyles(".c-cards__item:nth-child(even)");
   ScrollTrigger.matchMedia({
      '(min-width: 992px)': () => {
         gsap.utils.toArray('.c-cards__item:nth-child(even)').forEach(function (e, i) {
            gsap.timeline({
               defaults: {
                  ease: 'none'
               },
               scrollTrigger: {
                  trigger: document.querySelectorAll('.c-cards__item:nth-child(odd)')[i],
                  start: 'top bottom',
                  end: 'bottom top',
                  scrub: true
               }
            }).fromTo(e, {
               y: 100
            }, {
               y: -100
            });
         });
      }
   });
}

function lazyVideos() {
   const lazyVideos = document.querySelectorAll('[data-video]');

   if (!lazyVideos.length) return;

   lazyVideos.forEach(lazyVideo => {
      const vid = lazyVideo.querySelector('[data-video-src]');
      const img = lazyVideo.querySelector('[data-video-img]');
      const btn = lazyVideo.querySelector('[data-video-btn]');

      if (!vid) return;

      if (img) {
         vid.addEventListener('lazybeforeunveil', () => {
            gsap.to(img, {
               duration: .3,
               autoAlpha: 0
            });
         });
      }

      if (btn) {
         btn.addEventListener('click', () => {
            btn.classList.add('c-play-btn--active');
            vid.currentTime = 0;
            vid.controls = true;
            vid.muted = false;
         });
      }
   });
}